<template>
    <div class="case_data">
      <el-tabs v-model="activeName">
        <el-tab-pane label="走访需求详情" name="first"></el-tab-pane>
        <div class="case_data_over">
          <div class="case_data_dialog" ref="pdfContent">
            <div class="case_tabel">
              <div class="case_table_li">
                <div class="left">
                  <div class="case_table_li_left">需求描述</div>
                  <div class="case_table_li_right">{{ caseInfo.title }}</div>
                </div>
                <div class="right">
                  <div class="case_table_li_left">走访时间</div>
                  <div class="case_table_li_right">{{ caseInfo.createTime }}</div>
                </div>
              </div>
              <div class="case_table_li">
                <div class="left">
                  <div class="case_table_li_left">预算费用</div>
                  <div class="case_table_li_right">{{ caseInfo.title }}</div>
                </div>
                <div class="right">
                  <div class="case_table_li_left">所属企业</div>
                  <div class="case_table_li_right">{{ caseInfo.createTime }}</div>
                </div>
              </div>
              <div class="case_table_li">
                <div class="left">
                  <div class="case_table_li_left">所在地区</div>
                  <div class="case_table_li_right">{{ caseInfo.companyFullName }}</div>
                </div>
                <div class="right">
                  <div class="case_table_li_left">客户经理</div>
                  <div class="case_table_li_right">
                    <img class="customerHead" :src="caseInfo.customerHead" alt="">
                    {{ caseInfo.customerName }}</div>
                </div>
              </div>
          
  
            </div>
          </div>
        </div>
      </el-tabs>
  
      <div style="text-align: center; margin-top: 10px">
        <el-button type="primary" @click="sumbitClick">返回</el-button>
        <!-- <el-button @click="DialogVisible = false">取 消</el-button> -->
      </div>
    </div>
  </template>
      
      <script>
  import { getdetails } from '@/api/plan.js'
  
  export default {
    name: 'serviceinfo',
  
    data() {
      return {
        activeName: 'first',
        caseInfo: {} //案例详情数据
      }
    },
    computed: {
      id() {
        return this.$route.query.id
      },
    },
    created() {
      this.search()
    },
    methods: {
      // 点击保存
      sumbitClick() {
        this.$router.go(-1)
      },
      // 点击详情
      async search() {
        const res = await getdetails({ id: this.id })
        if (res.resultCode === 200) {
          this.caseInfo = res.data
          
        }
      },
  
     
    }
  }
  </script>
      
      <style lang="less" scoped>
  ::v-deep .el-radio__label {
    font-size: 14px;
    padding-left: 10px;
    display: flex;
    align-items: center;
  }
  ::v-deep .el-progress {
    width: 32px !important;
    height: 32px !important;
  }
  ::v-deep .el-progress-circle {
    width: 30px !important;
    height: 30px !important;
  }
  ::v-deep .el-radio-group {
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
    font-size: 0;
    display: flex;
  }
  ::v-deep .el-radio__inner {
    border: 1px solid #dcdfe6;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    background-color: #fff;
    position: relative;
    cursor: pointer;
    display: inline-block;
    box-sizing: border-box;
  }
  ::v-deep .el-dialog__body {
    padding: 0px 20px 30px 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
  ::v-deep .el-radio__inner::after {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: #fff;
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
    -webkit-transition: -webkit-transform 0.15s ease-in;
    transition: -webkit-transform 0.15s ease-in;
    transition: transform 0.15s ease-in;
    transition: transform 0.15s ease-in, -webkit-transform 0.15s ease-in;
  }
  ::v-deep .el-radio {
    color: #606266;
    font-weight: 500;
    line-height: 1;
    position: relative;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    outline: 0;
    font-size: 14px;
    display: flex;
    margin-right: 30px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    img {
      margin-right: 10px;
    }
  }
  .case_data_dialog {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0px;
  
    .download {
      display: flex;
      margin-left: 10px;
      align-items: center;
      box-sizing: border-box;
      padding: 6px 10px;
      border-radius: 4px;
      border: 1px solid #4e93fb;
      color: #4e93fb;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
  
      img {
        width: 20px !important;
        height: 20px !important;
      }
    }
  
    .compny_flex {
      padding: 12px 0px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e7e7e7;
  
      img {
        width: 30px;
        height: 30px;
      }
      .right_border {
        display: inline-block;
        width: 2px;
        height: 20px;
        background: #4e93fb;
      }
      .right {
        margin-left: 14px;
        color: #4e93fb;
        font-size: 16px;
      }
      .color_add {
        color: #448aff;
        margin-left: 10px;
      }
    }
  
    .case_tabel .case_table_li:nth-last-child(1) {
      border-bottom: 1px solid #dcdee0;
    }
  
    .case_tabel {
      width: 100%;
      overflow: hidden;
      font-size: 16px;
      margin-top: 10px;
      font-weight: 400;
      .img_radio {
        width: 20px;
        height: 20px;
      }
      .case_table_li {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        border-right: 1px solid #dcdee0;
        border-top: 1px solid #dcdee0;
  
        .left {
          width: 50%;
          display: flex;
          align-items: center;
          line-height: 80px;
          box-sizing: border-box;
        }
  
        .right {
          width: 50%;
          display: flex;
          align-items: center;
          line-height: 80px;
          box-sizing: border-box;
        }
  
        .case_table_li_left {
          width: 120px;
          background: #fafafa;
          display: flex;
          font-weight: 550;
          align-items: center;
          border-right: 1px solid #dcdee0;
          justify-content: center;
          border-left: 1px solid #dcdee0;
        }
        .case_table_li_left_two {
          width: 120px;
          background: #fafafa;
          font-weight: 550;
          line-height: 40px;
          border-right: 1px solid #dcdee0;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-left: 1px solid #dcdee0;
          .po_bottom{
            position: relative;
            top: -8px;
          }
          .po_top{
            position: relative;
            top: 8px;
          }
        }
        .case_table_li_right {
          flex: 1;
          display: flex;
          box-sizing: border-box;
          padding: 0 12px 0px 30px;
          align-items: center;
          line-height: 2em;
          min-height: 80px;
          flex-shrink: 1;
          .customerHead{
            width: 30px;
            height: 30px;
            margin-right: 10px;
            border-radius: 50%;
          }
          .img {
            display: flex;
            align-items: center;
            img {
              width: 100px;
              height: 64px;
              border-radius: 8px;
              margin-right: 16px;
            }
          }
        }
      }
    }
  }
  ::v-deep .el-tabs__item {
    padding: 0 1.25rem;
    height: 2.5rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 2.5rem;
    display: inline-block;
    list-style: none;
    font-size: 18px;
    font-weight: 500;
    position: relative;
  }
  .case_data {
    display: flex;
    flex-flow: column;
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    background-color: #fff;
    .case_data_over {
      height: calc(100vh - 250px);
      overflow: auto;
      /* 滚动条整体部分 */
      &::-webkit-scrollbar {
        width: 3px;
        height: 9px;
      }
      /* 滚动条里面的滑块 */
      &::-webkit-scrollbar-thumb {
        background-color: rgba(144, 147, 153, 0.3);
        border-radius: 20px;
        transition: background-color 0.3s;
      }
    }
    .new_page {
      margin: auto 0;
      padding: 10px;
      box-sizing: border-box;
      width: 100%;
      text-align: center;
    }
  
    .row {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      justify-content: space-between;
  
      .span {
        color: #393939;
        font-size: 16px;
        font-weight: 400;
      }
  
      ::v-deep .el-form--inline .el-form-item {
        margin-bottom: 0 !important;
      }
  
      .search_form {
        display: flex;
        align-items: center;
      }
  
      .search_right {
        margin-right: 20px;
        ::v-deep .el-button {
          border: 1px solid #448aff;
          color: #fff;
          border-radius: 0;
          background-color: #448aff;
        }
      }
    }
  }
  </style>
      